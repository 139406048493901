<template>
  <section class="add-programare history-modal flex" @mousedown="_clickModal">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <validation-provider rules="required" v-slot="{ errors }">
          <div class="relative">
            <i class="absolute left0 top0 flex-center" v-html="svg.calendar">
            </i>
            <datepicker
              v-model="data.createdAt"
              :class="{ 'invalid-input': errors[0] }"
              :monday-first="true"
              :language="$data.ro"
              :format="_customFormatter"
              placeholder="data"
              name="uniquename"
              :disabled-dates="disabledDates"
            />
          </div>
        </validation-provider>

        <div class="interval-orar flex flex-between mtop12px">
          <div class="flex align-center">
            <label class="flex-auto pright8px">De la:</label>
            <validation-provider rules="required" v-slot="{ errors }">
              <input
                type="text"
                data-test="programare-de-la-ora"
                v-model="data.deLa.ora"
                maxlength="2"
                @keypress="_input($event, 'digits')"
                :class="{ 'invalid-input': errors[0] }"
                placeholder="hh"
              />
            </validation-provider>
            <span>:</span>
            <validation-provider
              rules="required|lt:60"
              name="test"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="data.deLa.minute"
                data-test="programare-de-la-minute"
                maxlength="2"
                @keypress="validateMinute"
                :class="{ 'invalid-input': errors[0] }"
                placeholder="mm"
              />
            </validation-provider>
          </div>

          <div class="flex align-center">
            <label class="flex-auto pright8px">Până la:</label>
            <validation-provider
              :rules="'required|eqOrGt:' + data.deLa.ora"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="data.panaLa.ora"
                data-test="programare-pana-la-ora"
                :disabled="!data.deLa.ora || !data.deLa.minute"
                maxlength="2"
                :class="{ 'invalid-input': errors[0] }"
                placeholder="hh"
              />
            </validation-provider>
            <span>:</span>
            <validation-provider
              :rules="`required|lt:60|eqOrGtMinutes:${data.deLa.ora},${data.deLa.minute},${data.panaLa.ora}`"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="data.panaLa.minute"
                data-test="programare-pana-la-minute"
                :disabled="!data.deLa.ora"
                maxlength="2"
                :class="{ 'invalid-input': errors[0] }"
                placeholder="mm"
              />
            </validation-provider>
          </div>
        </div>

        <!--  MEDIC CURANT -->
        <div class="mtop12px">
          <drop-down
            :data="ddMedic"
            :value="
              pacient.medic || (user.rol.includes('medic') ? user.nume : '')
            "
            @selected="setMedic"
          />
        </div>

        <textarea
          class="mtop12px"
          v-model="data.detalii"
          cols="30"
          rows="5"
          placeholder="detalii"
        ></textarea>

        <!-- SMS CHECKBOX -->
        <check-box class="mtop12px" :payload="sms" :value="data.trimite_sms" />

        <div class="flex flex-between align-center">
          <!-- DELETE -->
          <button
            v-if="data.send_day_before_sms"
            class="delete"
            @click.stop="promptDelete"
          >
            <i v-html="svg.delete"></i>
          </button>

          <div class="prompt-delete" v-if="isDeletePrompt">
            <p>{{ STRINGS.PROGRAMARE.CONFIRMARE_STERGERE }}</p>
            <div class="flex flex-between">
              <span class="pointer" @click="promptDelete('no')">NU</span>
              <span class="pointer" @click.stop="deleteProgramare">DA</span>
            </div>
          </div>

          <span
            v-if="programareInvalida"
            data-test="notificare-programare-invalida"
            class="red font-medium"
            >Exista deja programari in intervalul specificat.</span
          >

          <button
            class="submit flex-center m-left-auto flex-auto submit-active mright7px"
            data-test="save-programare"
          >
            <i v-html="svg.save"></i>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
  import CheckBox from '../components/checkbox';
  import { STRINGS } from '../store/strings';
  import { mapState } from 'vuex';
  import moment from 'moment';
  import { historyModal } from '../mixins/history-modal';
  export default {
    name: 'add-programare',
    components: {
      CheckBox
    },

    mixins: [historyModal],

    data () {
      return {
        moment,
        isDeletePrompt: false,
        isDeleteBtn: undefined,
        ddMedic: {
          name: 'medic',
          placeholder: 'medic curant',
          ddData: 'medici',
          validation: true,
          default: '',
          capitalize: true
        },
        submitted: false,
        idDelete: false,
        STRINGS,
        sms: {
          name: 'sms',
          label: 'Trimite SMS',
          isActive: this.$store.state.modalProgramare.trimite_sms,
          clbk: (val) => {
            this.data.trimite_sms = val;
          }
        },
        programareInvalida: false,
        disabledDates: {
          to: new Date()
        }
      };
    },

    created () {
      this.$store.dispatch('get_medici');
    },

    mounted () {
      this.isDeleteBtn = this.data.denumire;
      this.showDinteDestinatie = this.data.dinteDestinatie;
    },

    computed: {
      ...mapState({
        programari: (state) =>
          state.pacient.programari || state.programari || [],
        data: 'modalProgramare',
        isUpdateModal: 'isUpdateModal',
        user: 'user',
        pacient: 'pacient',
        svg: 'svg'
      })
    },

    methods: {
      submit () {
        if (!this.idDelete) {
          !this.data.medic && (this.data.medic = this.pacient.medic);
          this.data.send_same_day_sms
            ? this.$store.dispatch('update_programare')
            : this.$store.dispatch('save_programare');
          // if (this.validateProgramare()) {
          // this.data.send_same_day_sms
          //   ? this.$store.dispatch('update_programare')
          //   : this.$store.dispatch('save_programare')
          // }
          // else {
          //   this.programareInvalida = true
          // }
        }
      },

      validateProgramare () {
        /* daca nu exista nici o programare pe acest pacient, sau nu exist in ziua aleasa sau daca
         * programarile sunt pe alt medic, atunci automat programarea e validata */
        const PROGRAMARI = this.programari;
        const PROGRAMARI_ZIUA_SELECTATA = this.programari.filter((o) =>
          this.isSameDay(this.data.createdAt, o.createdAt)
        );
        if (
          PROGRAMARI_ZIUA_SELECTATA.length === 0 ||
          this.programari.every((o) => o.medic !== this.data.medic)
        ) {
          return true;
        } else {
          /* check interval orar */
          /* dela ptr programarea curenta nu trebuie sa se afle intre programarile existente */
          const between = PROGRAMARI.filter(
            (o) =>
              this.isSameDay(this.data.createdAt, o.createdAt) &&
              o.medic === this.data.medic
          ).every((o) => {
            /* verific daca deLa e intre orele unei programari */
            return (
              (this.hmsToMs(this.data, 'deLa') >= this.hmsToMs(o, 'deLa') &&
                this.hmsToMs(this.data, 'deLa') < this.hmsToMs(o, 'panaLa')) ||
              (this.hmsToMs(this.data, 'panaLa') > this.hmsToMs(o, 'deLa') &&
                this.hmsToMs(this.data, 'panaLa') <= this.hmsToMs(o, 'panaLa'))
            );
          });
          const outside = PROGRAMARI.filter(
            (o) =>
              this.isSameDay(this.data.createdAt, o.createdAt) &&
              o.medic === this.data.medic
          ).some((o) => {
            return (
              (this.hmsToMs(o, 'deLa') || this.hmsToMs(o, 'panaLa')) >=
                this.hmsToMs(this.data, 'deLa') &&
              (this.hmsToMs(o, 'deLa') || this.hmsToMs(o, 'panaLa')) <
                this.hmsToMs(this.data, 'panaLa')
            );
          });
          console.log(between);
          console.log(outside);
          return !(between || outside);
        }
      },

      isSameDay (dateOne, dateTwo) {
        return (
          this.moment(dateOne, 'year').isSame(dateTwo, 'year') &&
          this.moment(dateOne, 'month').isSame(dateTwo, 'month') &&
          this.moment(dateOne, 'day').isSame(dateTwo, 'day')
        );
      },

      hmsToMs (payload, point) {
        const h = new Date(payload.createdAt).setHours(payload[point].ora);
        const m = new Date(h).setMinutes(payload[point].minute);
        return new Date(m).setSeconds(0);
      },

      setMedic (data) {
        this.data.medic = data.value;
      },

      promptDelete (action) {
        this.idDelete = action !== 'no';
        this.isDeletePrompt = action !== 'no';
      },

      deleteProgramare () {
        this.$store.dispatch('delete_programare');
      },

      validateOra (ev) {
        +ev.key > 1 && ev.target.value.length < 1 && ev.preventDefault();
      },

      validateMinute (ev) {
        +ev.key > 5 && ev.target.value.length < 1 && ev.preventDefault();
      }
    },

    destroyed () {
      this.$store.commit('SET_KEY_VALUE', {
        key: 'modalProgramare',
        value: {
          createdAt: new Date(new Date().setDate(new Date().getDate() + 1)),
          trimite_sms: true,
          deLa: { ora: '', minute: '' },
          panaLa: { ora: '', minute: '' }
        }
      });
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/mixin";
  @import "../assets/styles/var";
  .add-programare {
    .delete,
    .actions {
      margin-top: 20px;
    }

    .interval-orar {
      label,
      span {
        font-size: 1.6rem;
      }
      input {
        margin: 0 4px;
        width: 50px;
        padding-left: 0 !important;
        text-align: center;
      }
    }

    .delete {
      width: 65px;
      height: 65px;
      background-color: white;
      padding: 0;
      svg path {
        transition: fill 0.1s ease-in-out;
        fill: @red;
      }
      &:hover {
        background-color: white !important;
        svg path {
          fill: darken(@red, 20%, relative);
        }
      }
    }

    .prompt-delete {
      width: 200px;
      margin-top: 20px;
      text-align: center;
      font-size: 1.3rem;
      div {
        width: 50%;
        margin: 8px auto 0;
        span {
          width: 40px;
          height: 20px;
          padding-top: 4px;
          text-align: center;
          font-weight: bold;
          border-radius: 4px;
          color: white;
          transition: background-color 0.1s ease-in-out;
          &:first-child {
            background-color: @red;
            &:hover {
              background-color: darken(@red, 20%, relative);
            }
          }
          &:last-child {
            background-color: @main-green;
            &:hover {
              background-color: darken(@main-green, 20%, relative);
            }
          }
        }
      }
    }
  }
</style>
